import { HomeIcon } from '@heroicons/react/20/solid'
import { Link } from '@remix-run/react'

export type Breadcrumb = { name: string; href: string; current?: boolean }
type BreadcrumbsProps = { parts: Breadcrumb[] }
export default function Breadcrumbs({ parts }: BreadcrumbsProps) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link
              to="/app"
              className="text-gray-400 hover:text-gray-500"
              unstable_viewTransition
            >
              <HomeIcon className="h-5 w-5 flex-shrink-0" />
            </Link>
          </div>
        </li>
        {parts.map((part: Breadcrumb) => (
          <li key={part.name}>
            <div className="flex items-center">
              <svg
                className="h-5 w-5 flex-shrink-0 text-gray-300"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              {part.current ? (
                <Link
                  to="#"
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  {part.name}
                </Link>
              ) : (
                <Link
                  to={part.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  unstable_viewTransition
                >
                  {part.name}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}
